import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { basename } from "../config/buildSettings.js";

class PublicHeader extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.handleNavigateSearch = this.handleNavigateSearch.bind(this);
    this.handleNavigateLogin = this.handleNavigateLogin.bind(this);
    this.handleNavigateFaq = this.handleNavigateFaq.bind(this);
    this.handleNavigateTutorial = this.handleNavigateTutorial.bind(this);
    this.handleNavigatePublicKPI = this.handleNavigatePublicKPI.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  handleNavigateSearch(e) {
    e.preventDefault();
    localStorage.removeItem("search");
    this.props.history.push({ pathname: "/" });
  }

  handleNavigateLogin(e) {
    e.preventDefault();
    this.props.history.push({ pathname: "/login" });
  }

  handleNavigateTutorial(e) {
    e.preventDefault();
    this.props.history.push({ pathname: "/tutorial" });
  }

  handleNavigateFaq(e) {
    e.preventDefault();
    this.props.history.push({ pathname: "/faq" });
  }

  handleNavigateRemediation(e) {
    e.preventDefault();
    window.open(
      "https://anronline.vermont.gov/?formtag=DW_LeadRemediation",
      "_blank"
    );
  }

  handleNavigatePublicKPI() {
    this.props.history.push({ pathname: "/summary" });
  }

  render() {
    return (
      <span>
        <Container className="top-bar-outer-container">
          <Container className="top-bar-inner-container">
            <Row className="vermont-official-row align-items-center">
              <Col>
                <a
                  rel="noopener noreferrer"
                  alt="Moon over Mountains Logo"
                  href="http://vermont.gov/portal/"
                  target="_blank"
                  className="vermont-official"
                >
                  VERMONT OFFICIAL STATE WEBSITE
                </a>
              </Col>
              <Col>
                <a
                  rel="noopener noreferrer"
                  alt="State of Vermont Link"
                  href="http://vermont.gov/portal/"
                  target="_blank"
                  className="vermont-logo"
                  aria-label="vermont logo"
                >
                  {" "}
                </a>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container className="site-name-outer-container box-shadow">
          <Container className="site-name-inner-container">
            <Row className="site-name-row align-items-center">
              <Col>
                <div className="site-name">STATE OF VERMONT</div>
                <a
                  rel="noopener noreferrer"
                  alt="Site Subname Link"
                  href={basename}
                  className="site-subname"
                >
                  Lead in School and Child Care Drinking Water Results
                </a>
              </Col>
            </Row>
          </Container>
          <Container className="top-navigation-inner-container">
            <Row className="top-navigation-row align-items-center">
              <Navbar
                className="top-navigation-bar"
                color="#efefe3"
                light
                expand="sm"
              >
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={this.state.isOpen} navbar>
                  <Nav className="ml-auto" navbar>
                    <NavItem
                      // className={
                      //   this.props.location.pathname === "/"
                      //     ? "top-navigation-link-highlighted"
                      //     : ""
                      // }
                      onClick={this.handleNavigateSearch}
                    >
                      <NavLink className="top-navigation-link">SEARCH</NavLink>
                    </NavItem>
                    <NavItem onClick={this.handleNavigateRemediation}>
                      <NavLink className="top-navigation-link">
                        REMEDIATION FORM
                      </NavLink>
                    </NavItem>
                    {window.location.href
                      .toLowerCase()
                      .search("anrweb.vt.gov/dec/leadinschools") !== -1 ||
                      window.location.href
                      .toLowerCase()
                      .search("pubapps.vtanr.state.vt.us/dec/leadinschools") !== -1 ||
                    window.location.href
                      .toLowerCase()
                      .search("localhost:3000") !== -1 ? (
                      <NavItem
                        // className={
                        //   this.props.location.pathname === "/login"
                        //     ? "top-navigation-link-highlighted"
                        //     : ""
                        // }
                        onClick={this.handleNavigateLogin}
                      >
                        <NavLink className="top-navigation-link">
                          STATE EMPLOYEE LOGIN
                        </NavLink>
                      </NavItem>
                    ) : (
                      <span />
                    )}
                    <NavItem>
                      <NavLink
                        className="top-navigation-link"
                        onClick={this.handleNavigateFaq}
                      >
                        FAQ
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className="top-navigation-link"
                        onClick={this.handleNavigateTutorial}
                      >
                        TUTORIAL
                      </NavLink>
                    </NavItem>
                    <UncontrolledDropdown
                      nav
                      inNavbar
                      className="top-navigation-dropdown"
                    >
                      <DropdownToggle nav caret>
                        REPORTS
                      </DropdownToggle>
                      <DropdownMenu className="top-navigation-dropdown-menu">
                        <DropdownItem
                          onClick={() => this.handleNavigatePublicKPI()}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>OVERALL SUMMARY</NavLink>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Nav>
                </Collapse>
              </Navbar>
            </Row>
          </Container>
        </Container>
        <br />
        <br />
      </span>
    );
  }
}

export default PublicHeader;
