import React, { Component } from "react";
import { Container, Row, Col, Table } from "reactstrap";
import {
  Button,
  Form,
  Input,
  Alert,
  // InputGroup,
  // InputGroupAddon,
  Label
} from "reactstrap";
import { BounceLoader } from "react-spinners";
import { css } from "@emotion/core";
// import Select from "react-select";
import { base_URL, credentials } from "../config/buildSettings.js";
import { apiKey } from "../config/index.js";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class Search extends Component {
  constructor(props) {
    super(props);

    this.fetchTownList = this.fetchTownList.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.toggleMessageAlert = this.toggleMessageAlert.bind(this);
    this.toggleErrorAlert = this.toggleErrorAlert.bind(this);
    this.renderHeading = this.renderHeading.bind(this);

    this.state = {
      data: {},
      searchLoading: false,
      error: "",
      search: "",
      towns: [],
      town: "All Towns",
      type: "Both",
      message: "",
      school: "",
      schoolHeading: "School or Child Care",
      showMessageAlert: false,
      showErrorAlert: false,
      isTest: false,
      isInternal: false
    };
  }

  componentDidMount() {
    this.fetchTownList();
    if (localStorage.getItem("search")) {
      let search = JSON.parse(localStorage.getItem("search"));
      this.setState({ data: search });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.location.pathname.search("test") !== -1 &&
        !this.state.isTest) ||
      (this.props.location.pathname.search("test") === -1 &&
        this.state.isTest) ||
      (this.props.location.pathname.search("internal") !== -1 &&
        !this.state.isInternal) ||
      (this.props.location.pathname.search("internal") === -1 &&
        this.state.isInternal)
    ) {
      this.fetchTownList();
      this.setState({
        data: {},
        error: "",
        search: "",
        town: "All Towns",
        type: "Both",
        message: "",
        school: "",
        showMessageAlert: false,
        showErrorAlert: false
      });
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  fetchTownList() {
    let url, useCredentials;
    //console.log(base_URL);
    // Use credentials if test
    if (this.props.location.pathname.search("test") !== -1) {
      this.setState({ isTest: true, isInternal: false });
      url = `${base_URL}/api/internal/townstest`;
      useCredentials = "include";
    } else if (this.props.location.pathname.search("internal") !== -1) {
      this.setState({ isTest: false, isInternal: true });
      url = `${base_URL}/api/internal/towns`;
      useCredentials = "include";
    } else {
      this.setState({ isTest: false, isInternal: false });
      url = `${base_URL}/api/public/towns`;
      useCredentials = credentials;
    }

    fetch(url, {
      method: "POST",
      credentials: useCredentials,
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        apiKey: apiKey
      })
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(json => {
        if (json.length === 0) {
          this.setState({
            message: "No Towns Found",
            showMessageAlert: true
          });
        }
        this.setState({ towns: json });
      })
      .catch(error => {
        console.error(error);
        this.setState({ error: error.message, showErrorAlert: true });
      });
  }

  toggleErrorAlert() {
    this.setState({
      showErrorAlert: !this.state.showErrorAlert,
      error: ""
    });
  }

  toggleMessageAlert() {
    this.setState({
      showMessageAlert: !this.state.showMessageAlert,
      message: ""
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSearch(event) {
    event.preventDefault();
    this.setState({
      message: "",
      showMessageAlert: false
    });

    this.timer = setTimeout(() => {
      this.setState({ searchLoading: true });
      this.timer = 0;
    }, 250);

    let url, useCredentials;

    // Use credentials if test
    if (this.props.location.pathname.search("test") !== -1) {
      url = `${base_URL}/api/internal/schoolstest`;
      useCredentials = "include";
    } else if (this.props.location.pathname.search("internal") !== -1) {
      url = `${base_URL}/api/internal/schools`;
      useCredentials = "include";
    } else {
      url = `${base_URL}/api/public/schools`;
      useCredentials = credentials;
    }

    // console.log(url);
    fetch(url, {
      method: "POST",
      credentials: useCredentials,
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        search: this.state.search,
        town: this.state.town,
        type: this.state.type,
        apiKey: apiKey
      })
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(json => {
        if (!json || json.length === 0) {
          let message;
          switch (this.state.type) {
            case "Both":
              message = "No Schools or Child Cares With Results Found";
              break;
            case "School":
              message = "No Schools With Results Found";
              break;
            case "Child Care":
              message = "No Child Cares With Results Found";
              break;
            default:
              message = "No Schools or Child Cares With Results Found";
              break;
          }

          if (this.timer) {
            clearTimeout(this.timer);
            this.timer = 0;
          }
          this.setState({
            message: message,
            showMessageAlert: true,
            searchLoading: false,
            data: {}
          });
          return;
        }
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = 0;
        }
        this.renderHeading();
        this.setState({ data: json, searchLoading: false });
      })
      .catch(error => {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = 0;
        }
        this.setState({
          error: error.message,
          showErrorAlert: true,
          searchLoading: false,
          data: {}
        });
      });
  }

  handleSelect(School_ID) {
    localStorage.setItem("search", JSON.stringify(this.state.data));
    if (this.props.location.pathname.search("test") !== -1) {
      this.props.history.push({ pathname: "/testschool/" + School_ID });
    } else if (this.props.location.pathname.search("internal") !== -1) {
      this.props.history.push({ pathname: "/internalschool/" + School_ID });
    } else {
      this.props.history.push({ pathname: "/school/" + School_ID });
    }
  }

  renderHeading() {
    let heading = "School or Child Care";
    switch (this.state.type) {
      case "Both":
        heading = "School or Child Care";
        break;
      case "School":
        heading = "School";
        break;
      case "Child Care":
        heading = "Child Care";
        break;
      default:
        heading = "School or Child Care";
        break;
    }

    this.setState({
      schoolHeading: heading
    });
  }

  render() {
    return (
      <span>
        {this.state.school === "" && (
          <Container style={{ textAlign: "center" }}>
            <Row>
              <Col md={{ size: 12 }} lg={{ size: 10, offset: 1 }}>
                <h4>
                  {this.props.location.pathname.search("test") !== -1
                    ? "Test Search for School or Child Care"
                    : "Search for School or Child Care"}
                </h4>
                <br />
                <Form onSubmit={this.handleSearch}>
                  <Row form>
                    <Col
                      md={{ size: 6 }}
                      lg={{ size: 4 }}
                      style={{ marginBottom: "1rem" }}
                    >
                      {/* <InputGroup> */}
                      <Label
                        // className="col-sm-3 col-form-label input-label"
                        for="searchInput"
                      >
                        Name
                      </Label>
                      <Input
                        type="text"
                        name="search"
                        id="searchInput"
                        onChange={this.handleInputChange}
                        placeholder="School, Child Care or Provider Name"
                      />
                      {/* <InputGroupAddon addonType="append">
                      <Button type="submit" color="primary">
                        Search
                      </Button>
                    </InputGroupAddon> */}
                      {/* </InputGroup> */}
                      {/* <br /> */}
                    </Col>
                    <Col
                      md={{ size: 6 }}
                      lg={{ size: 4 }}
                      style={{ marginBottom: "1rem" }}
                    >
                      {" "}
                      {/* <InputGroup> */}
                      <Label
                        // className="col-sm-3 col-form-label input-label"
                        for="townInput"
                      >
                        Town
                      </Label>
                      {/* <div className="col-sm-9"> */}
                      <Input
                        type="select"
                        name="town"
                        id="townInput"
                        value={this.state.town}
                        onChange={this.handleInputChange}
                      >
                        {this.state.towns.length > 0 &&
                          this.state.towns.map(
                            town =>
                              town.Town && (
                                <option key={town.Town} value={town.Town}>
                                  {town.Town}
                                </option>
                              )
                          )}
                        {!this.state.towns.length > 0 && (
                          <option key="All Towns" value="All Towns">
                            All Towns
                          </option>
                        )}
                      </Input>
                      {/* </div> */}
                      {/* </InputGroup> */}
                    </Col>
                    <Col
                      md={{ size: 6 }}
                      lg={{ size: 4 }}
                      style={{ marginBottom: "1rem" }}
                    >
                      {" "}
                      {/* <InputGroup> */}
                      <Label
                        // className="col-sm-3 col-form-label input-label"
                        for="typeInput"
                      >
                        Type
                      </Label>
                      {/* <div className="col-sm-9"> */}
                      <Input
                        type="select"
                        name="type"
                        id="typeInput"
                        value={this.state.type}
                        onChange={this.handleInputChange}
                      >
                        <option key="1" value="Both">
                          School or Child Care
                        </option>
                        <option key="2" value="School">
                          School
                        </option>
                        <option key="3" value="Child Care">
                          Child Care
                        </option>
                      </Input>
                      {/* </div> */}
                      {/* </InputGroup> */}
                    </Col>
                  </Row>
                  {/* {this.state.towns.length > 0 && (
                    <Select
                      name="town"
                      value={this.state.town}
                      options={this.state.towns}
                      onInputChange={town => this.setState({ town })}
                      labelKey="Town"
                      valueKey="Town"
                    />
                  )} */}
                  <Col md={{ size: 12 }}>
                    <Button type="submit" color="primary">
                      Search
                    </Button>
                  </Col>
                </Form>
                <br />                
                {!this.state.data.length && (
                  <span style={{ textAlign: "left", display: "block" }}>
                    <strong>Note:</strong> Results are posted one week after a school or child care receives them. If no results are found for your
                    school or child care, most likely they have not yet
                    collected samples or received their results. Results for
                    school-based child care programs (such as preschool or
                    afterschool) are listed under the school where the program
                    is located.
                    {/* {new Date() < new Date('2023-10-11') && (
                      <><br />
                      <br />
                      <span style={{color:'red'}}><strong>The Agency of Natural Resources will be performing system maintenance on October 10th from 2pm-midnight EST. This website will be unavailable during that time.</strong></span>
                      <br />
                      </>
                    )} */}
                  </span>
                )}
                {this.state.error !== "" && (
                  <Alert
                    color="warning"
                    isOpen={this.state.showErrorAlert}
                    toggle={this.toggleErrorAlert}
                  >
                    {this.state.error}
                  </Alert>
                )}
                {this.state.message !== "" && (
                  <Alert
                    color="info"
                    isOpen={this.state.showMessageAlert}
                    toggle={this.toggleMessageAlert}
                  >
                    {this.state.message}
                  </Alert>
                )}
                <span className="login-spinner">
                  <BounceLoader
                    size={60}
                    sizeUnit={"px"}
                    color={"#36D7B7"}
                    css={override}
                    loading={this.state.searchLoading}
                  />
                  <br />
                </span>
                {this.state.data.length > 0 && (
                  <Col
                  // xl={{ size: 10, offset: 1 }}
                  >
                    <span>
                      <Table className="search-table">
                        <thead>
                          <tr>
                            {/* <th>                             
                          </th> */}
                            <th>{this.state.schoolHeading}</th>
                            <th>Town</th>
                            <th>Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data.map(
                            school =>
                              school.School_Name && (
                                <tr key={school.School_ID + school.Type}>
                                  {/* <th  onClick={() => console.log()}>
                              <i className="fas fa-search-location" style={{cursor:'pointer'}}></i>
                              </th> */}
                                  <td>
                                    <span
                                      className="school-name"
                                      onClick={() =>
                                        this.handleSelect(school.School_ID)
                                      }
                                    >
                                      {school.Active_Facility === true ? school.School_Name : school.School_Name.concat(" (Inactive)")}
                                    </span>
                                  </td>
                                  <td>{school.Town}</td>
                                  <td>{school.Type}</td>
                                </tr>
                              )
                          )}
                        </tbody>
                      </Table>
                    </span>
                  </Col>
                )}
              </Col>
            </Row>
          </Container>
        )}
      </span>
    );
  }
}

export default Search;
